import React, { useContext } from 'react'

import { ChevronLink as AlliumChevronLink } from '@telus-uds/components-web'

import { IChevronLinkFields } from 'contentful-types'
import { SpacerProps } from '../Spacer/Spacer'

import useKoodoAnalyticsEvent from '../../../../hooks/useKoodoAnalyticsEvent'
import { appendFieldPaths, mapDataAttrsToDataSet } from '../../../utils/dataAttributes'
import { A11yTextRenderer } from '../../../utils/text/TextRenderer'
import { SiteBuilderContext } from '../../../renderer/context/SiteBuilderContext'
import BaseModal, { BaseModalProps } from '../BaseModal/BaseModal'
import useModalClick from '../../../../hooks/useModalClick'
import { BlockRenderer } from '../../../renderer/contentful/BlockRenderer'
import { useIntcmpContent, intcmpProps } from '../../../../hooks/useIntcmpContent'
import alloyEvents from '../../../utils/alloyAnalytics/link'
import { ContentfulAnalyticsEventType } from 'src/siteBuilder/utils/types/analytics'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

export type ChevronLinkProps = {
  text: string
  url: string
  intcmpRef?: intcmpProps
  analyticsUrlUsage: IChevronLinkFields['analyticsUrlUsage']
  direction?: 'Right' | 'Left'
  analyticsEvent?: ContentfulAnalyticsEventType
  alternative?: boolean
  inverse?: boolean
  size?: 'Small' | 'Micro' | 'Medium' | 'Large'
  target?: '_self' | '_blank' | '_parent' | '_top'
  spacer?: SpacerProps
  baseModal?: BaseModalProps
  onClick?: (e: MouseEvent) => void
}

const ChevronLink = ({
  text = '',
  direction = 'Right',
  url = '/',
  intcmpRef,
  analyticsUrlUsage,
  alternative = false,
  inverse = false,
  target = '_self',
  size = 'Medium',
  analyticsEvent,
  spacer,
  baseModal,
  onClick,
  ...rest
}: ChevronLinkProps) => {
  const [isAnalyticsTriggered, setIsAnalyticsTriggered] = React.useState(false)
  const { activeModal } = useContext(SiteBuilderContext)
  const { tryModalClick } = useModalClick(url, baseModal)

  useKoodoAnalyticsEvent(analyticsEvent, isAnalyticsTriggered)

  rest = appendFieldPaths(rest, ['.text#div[1]'])

  const handleOnClick = (e: MouseEvent) => {
    tryModalClick(e)
    setIsAnalyticsTriggered(true)
    alloyEvents.linkClick({ url, analyticsUrlUsage })
    if (onClick) onClick(e)
  }

  return (
    <div data-testid="chevron-link-testid">
      {renderSpacer(spacer)}
      {/* // TODO: Extract the base modal logic to removed repeated code in other Links */}
      {baseModal && (
        <BaseModal
          id={baseModal.id}
          entryTitle={baseModal.entryTitle}
          modalMaxWidth={baseModal.modalMaxWidth}
          heading={<BlockRenderer block={baseModal.heading as any} />}
          subHeading={<BlockRenderer block={baseModal.subHeading as any} />}
          components={<BlockRenderer block={baseModal.components as any} />}
          isOpen={activeModal === baseModal.id}
          disableAnalytics={baseModal.disableAnalytics}
        />
      )}
      <AlliumChevronLink
        href={useIntcmpContent(url, text, intcmpRef)}
        direction={direction.toLowerCase()}
        target={target}
        onClick={handleOnClick}
        variant={{
          size: size.toLowerCase(),
          alternative: alternative,
          inverse: inverse,
        }}
        dataSet={mapDataAttrsToDataSet(rest)}
      >
        <A11yTextRenderer>{text}</A11yTextRenderer>
      </AlliumChevronLink>
    </div>
  )
}

export default ChevronLink
