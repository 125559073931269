import { IChevronLinkFields } from 'contentful-types'

import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'
import { mapSpacer } from 'src/siteBuilder/renderer/contentful/mapSpacer'
import { mapModal } from '../../../renderer/contentful/mapModal'

// Component
import ChevronLink from './ChevronLink'

const ContentfulChevronLink = withContentful<IChevronLinkFields>(ChevronLink, {
  spacer: mapSpacer,
  baseModal: mapModal,
  analyticsUrlUsage: (props) => props.fields.analyticsUrlUsage,
})

export default ContentfulChevronLink
